/* custom classes */
.custom_event_base {
    height: 40px;
    padding-left: 15px;
    border: none;
    border-radius: 50px;
    box-shadow: 1px 2px 3px rgb(0 0 0 / 15%);
    cursor: pointer;
}

.custom_event_base:hover {
    opacity: 0.7;
    transition: all 0.4s ease;
}

.future_exam_or_holiday {
    box-shadow: 0 2px 3px #808080 !important;
}

/* custom classes */

.fc-event-title {
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 40px;
    text-overflow: ellipsis;
}

.fc-daygrid-day-number {
    opacity: 1;
}

.fc-daygrid-event-dot {
    border: none;
    margin-left: -13px;
}

.fc-event-time {
    display: none;
}

.fc-event:focus:after {
    background: none;
}

.fc-day-today > div {
    background-color: lightgoldenrodyellow;
}

.fc-day-past > div {
    background-color: #f8f8f8;
}

.fc-day-future > div {
    background-color: #ffffff;
}

.fc-col-header-cell-cushion {
    color: #2c5871;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
}

.fc-scrollgrid-sync-table, .fc-scrollgrid  {
    border-top: none !important;
    border-left: none !important;
    background-color: white !important;
    border-collapse: separate !important;
    border-spacing: 3px !important;
    box-shadow: 1px 2px 3px rgb(0 0 0 / 15%);
}

.fc-daygrid-day {
    border: none !important;
    background-color: #f7f7f7 !important;
}

.fc-col-header-cell {
    border: none !important;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: none !important;
}

audio {
    width: 100% !important;
}
